<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
               
              @click="$router.push({name: 'admin.funding-agencies.create'})"
            ><i data-feather="plus" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="data"
              :sort-mapping="sortMapping"
              :title="'funding agencies'"
              :total="dataTotal"
              :fetch-path="'fundingAgencies/fetch'"
              :filter-path="'fundingAgencies/filter'"
            >
              <template v-slot="{field}">
                <td class="text-end">
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit area and group"
                  ><a
                    class="btn btn-icon rounded-circle btn-flat-dark"
                    @click="$router.push({name: 'admin.funding-agencies.edit', params: { id: field.id }})"
                  ><i data-feather="edit-3" /></a></span>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete area & group"
                    @click="showDeleteModal(field.id)"
                  ><a
                    href="#"
                    class="btn btn-icon rounded-circle btn-flat-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#modalDeleteWithInput"
                  ><i data-feather="trash-2" /></a></span>
                </td>
              </template>
              <template #customfilters>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Name</label>
                  <input
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter($event.target.value, 'name')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Order</label>
                  <input
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter($event.target.value, 'order')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Active</label>
                  <select
                    id=""
                    name=""
                    class="form-select"
                    @input="saveFilter($event.target.value, 'active')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="true">
                      Yes
                    </option>
                    <option value="false">
                      No
                    </option>
                  </select>
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import CompleteTable from '../../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
  },
  data() {
    return {
      sortedField: 'Order',
      defaultFields: [
        {
          name: 'Order',
          checked: true,
          order: 1,
        },
        {
          name: 'Name',
          checked: true,
          order: 2,
        },
        {
          name: 'Acronym',
          checked: true,
          order: 3,
        },
        {
          name: 'Active',
          checked: true,
          order: 4,
        },
      ],
      sortMapping: {
        Order: 'order',
        Name: 'name',
        Acronym: 'code',
        Active: 'active',
      },
      title: 'Funding agencies',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      data: 'fundingAgencies/items',
      dataTotal: 'fundingAgencies/itemsTotal',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'grant_funding_agencies')
    await this.$store.dispatch('fundingAgencies/filter', this.$store.getters['filters/filters'])
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'grant_funding_agencies',
      })
    }

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'fundingAgencies', url: `${Vue.prototype.$groupUrl}/grants/funding-agencies/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
